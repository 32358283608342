<script setup lang="ts">
</script>

<template>
    <div class="bg-grad-dark text-text-on-primary flex flex-col items-center gap-10 py-14">
        <div class="flex justify-center gap-10 text-[36px]">
            <a href="https://twitter.com/briqNFT" rel="noopener" target="_blank"><p><i class="fab fa-twitter"/></p></a>
            <a href="https://discord.gg/kpvbDCw5pr" rel="noopener" target="_blank"><p><i class="fab fa-discord"/></p></a>
            <a href="https://github.com/briqNFT/" rel="noopener" target="_blank"><p><i class="fab fa-github"/></p></a>
        </div>
        <p class="text-center">
            Copyright © 2022 S&L Technologies. All rights reserved.
            <router-link class="font-medium" to="/legal">Legal terms</router-link>
        </p>
    </div>
</template>
